import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Speed Bench Press 4×4\\@60% 1RM`}</p>
    <p><em parentName="p">{`follow each set immediately with: `}</em></p>
    <p>{`D Ball Chest Pass 4×4`}</p>
    <p>{`Banded Straight Arm Pulldowns 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 30 HSPU’s`}</p>
    <p>{`4 Rounds of:`}</p>
    <p>{`20 Ring Dips`}</p>
    <p>{`20 GHD Situps`}</p>
    <p>{`Cash Out: 30 Hand Release Pushups`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      